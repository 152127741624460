import { object, string, number, date, array } from "yup"
// import sum from 'lodash/sum'
const phoneRegExp = /^(?=.*[0-9])[- +()0-9]+$/
const noSpaces = /^\S+$/

export const getValidationSchemaEmail = () => {
  return object().shape({
    email: string()
      .email("E-mail is not valid!")
      .required("E-mail is required!"),
  })
}

export const getValidationSchemaPassword = () => {
  return object().shape({
    password: string()
      .min(6, "Password has to be longer than 6 characters!")
      .required("Password is required!"),
  })
}

export const getValidationSchemaAgentApplication = () => {
  return object().shape({
    firstName: string()
      .min(2, "First Name is too short!")
      .required("First Name is required!"),
    lastName: string()
      .min(2, "Last Name is too short!")
      .required("Last Name is required!"),
    phone: string()
      .min(5, "Phone number is not valid!")
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone number is required!"),
    businessName: string()
      .min(2, "Business Name is too short!")
      .required("Business Name is required!"),
    job: string().required("Job Name is required!"),
    deliveryAddress: string()
      .min(2, "Delivery Address is too short!")
      .required("Delivery Address is required!"),
    province: string()
      .min(2, "Province is too short!")
      .required("Province is required!"),
    town: string()
      .min(2, "Town is too short!")
      .required("Town is required!"),
    workEmail: string()
      .email("E-mail is not valid!")
      .required("E-mail is required!"),
    vatNumber: string().min(5, "VAT number is not valid!"),
    warehouse: string().oneOf(["Johannesburg", "Cape Town"], "Pickup Warehouse is required!"),
  })
}

export const getValidationSchemaProfile = () => {
  return object().shape({
    email: string()
      .email("E-mail is not valid!")
      .required("E-mail is required!"),
    firstName: string()
      .min(2, "First Name is too short!")
      .required("First Name is required!"),
    lastName: string()
      .min(2, "Last Name is too short!")
      .required("Last Name is required!"),
    phone: string()
      .min(5, "Phone number is not valid!")
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone number is required!"),
    companyName: string()
      .min(2, "Company Name is too short!")
      .required("Company Name is required!"),
    vatNumber: string().min(5, "VAT number is not valid!"),
    address: string()
      .min(2, "Company Address is too short!"),
    warehouse: string().oneOf(["Johannesburg", "Cape Town"], "Pickup Warehouse is required!"),
  })
}

export const getValidationSchemaCustomer = () => {
  return object().shape({
    email: string()
      .email("E-mail is not valid!")
      .required("E-mail is required!"),
    customer: string()
      .min(2, "First Name is too short!")
      .required("First Name is required!"),
    phone: string()
      .min(5, "Phone number is not valid!")
      .required("Phone number is required!"),
  })
}

export const getValidationSchemaLogin = () => {
  return object().shape({
    email: string()
      .email("E-mail is not valid!")
      .required("E-mail is required!"),
    password: string()
      .min(6, "Password has to be longer than 6 characters!")
      .required("Password is required!"),
  })
}

// waterLevel = 30, A
// pumpDistance = 1000, D
// pipeDiameter = 32, E
// pumpHeight = 10, C
// waterNeeded = 3000 F

export const getValidationSchemaPump = () => {
  return object().shape({
    email: string().email("E-mail is not valid!"),
    customer: string().min(2, "Name is too short!"),
    phone: string()
      .min(5, "Phone number is not valid!")
      .matches(phoneRegExp, "Phone number is not valid"),
    waterLevel: number()
      .typeError("Static Water level must be a number!")
      .min(0, "Static Water level is not valid!")
      .required("Static Water level is required!"),
    pumpDistance: number()
      .typeError("Pump Distance must be a number!")
      .min(0, "Pump Distance is not valid!")
      .required("Pump Distance is required!"),
    pipeDiameter: number()
      .typeError("Pipe Diameter must be a number!")
      .positive("Pipe Diameter is not valid!")
      .required("Pipe Diameter is required!"),
    pumpHeight: number()
      .typeError("Pump Height must be a number!")
      //.positive('Pump Height is not valid!')
      .min(0, "Pump Height is not valid!")
      .required("Pump Height is required!"),
    totalWellDepth: number()
      .typeError("Total Well Depth must be a number!")
      //.positive('Pump Height is not valid!')
      .min(0, "Total Well Depth is not valid!")
      .when("waterLevel", (waterLevel, schema) => {
        return schema.min(
          waterLevel + 1,
          "Total Well Depth must be deeper than Static Water Level"
        )
      })
      .required("Total Well Depth is required!"),
    waterNeeded: number()
      .typeError("Water Needed must be a number!")
      .positive("Water Needed is not valid!")
      .required("Water Needed is required!"),
  })
}

export const getValidationSchemaPublicForm = () => {
  return object().shape({
    firstName: string().required("First Name is required!").min(2, "First Name is too short!"),
    lastName: string().required("Last Name is required!").min(2, "Last Name is too short!"),
    email: string().required("Email is required!").email("E-mail is not valid!"),
    phone: string()
      .required("Phone number is required!")
      .min(5, "Phone number is not valid!")
      .matches(phoneRegExp, "Phone number is not valid"),
    waterLevel: number()
      .typeError("Static Water level must be a number!")
      .min(0, "Static Water level is not valid!")
      .required("Static Water level is required!"),
    pumpDistance: number()
      .typeError("Pump Distance must be a number!")
      .min(0, "Pump Distance is not valid!")
      .required("Pump Distance is required!"),
    pipeDiameter: number()
      .typeError("Pipe Diameter must be a number!")
      .positive("Pipe Diameter is not valid!")
      .required("Pipe Diameter is required!"),
    pumpHeight: number()
      .typeError("Pump Height must be a number!")
      //.positive('Pump Height is not valid!')
      .min(0, "Pump Height is not valid!")
      .required("Pump Height is required!"),
    totalWellDepth: number()
      .typeError("Total Well Depth must be a number!")
      //.positive('Pump Height is not valid!')
      .min(0, "Total Well Depth is not valid!")
      .when("waterLevel", (waterLevel, schema) => {
        return schema.min(
          waterLevel + 1,
          "Total Well Depth must be deeper than Static Water Level"
        )
      })
      .required("Total Well Depth is required!"),
    waterNeeded: number()
      .typeError("Water Needed must be a number!")
      .positive("Water Needed is not valid!")
      .required("Water Needed is required!"),
  })
}

export const getValidationSchemaVeichi = () => {
  return object().shape({
    email: string().email("E-mail is not valid!"),
    customer: string().min(2, "Name is too short!"),
    phone: string()
      .min(5, "Phone number is not valid!")
      .matches(phoneRegExp, "Phone number is not valid"),
    voltage: number()
      .typeError("Voltage must be a number!")
      .required("Voltage is required!"),
    powerRating: number()
      .typeError("Power rating (kW) must be a number!")
      .min(0.1, "Power rating (kW) is not valid!")
      .required("Power rating (kW) is required!"),
    flow: number()
      .typeError("Flow must be a number!")
      .min(0, "Flow is not valid!")
      .required("Flow is required, you can use 0"),
    town: object()
      .typeError("Town is required!")
      .required("Town is required!"),
    panelMake: string().min(2, "Make is too short!").required("Make is required!"),
    panelVMP: number()
      .typeError("VMP must be a number!")
      .min(1, "VMP is not valid!")
      .required("VMP is required!"),
    panelVOC: number()
      .typeError("VOC must be a number!")
      .min(1, "VOC is not valid!")
      .required("VOC is required!"),
    panelWatt: number()
      .typeError("Watt must be a number!")
      .min(20, "Watt is not valid!")
      .required("Watt is required!"),
    numberOfStrings: number().when("manual", {
      is: (val) => (!!val),
      then: number().typeError("Number of strings must be a number!").required('Number of strings is required!').min(1, "Number of strings is not valid!"),
      otherwise: number(),
    }),
    panelsPerString: number().when("manual", {
      is: (val) => (!!val),
      then: number().typeError("Panels per string must be a number!").required('Panels per string is required!').min(1, "Number of panels per string is not valid!"),
      otherwise: number(),
    }),
  })
}


export const getValidationSchemaIrrigation = () => {
  return object().shape({
    taxRate: number()
      .typeError("Tax Rate must be a number!")
      .min(0, "Tax Rate is not valid!")
      .required("Tax Rate is required!"),
    systemCost: number()
      .typeError("System Cost must be a number!")
      .min(0, "System Cost is not valid!")
      .required("System Cost is required!"),
    loanPeriod: number()
      .typeError("Loan period must be a number!")
      .min(0, "Loan period is not valid!")
      .required("Loan period is required!"),
    interestRate: number()
      .typeError("Interest Rate must be a number!")
      .min(0, "Interest Rate is not valid!")
      .required("Interest Rate is required!"),
    electricityIncrease5Yr: number()
      .typeError("Electricity Increase must be a number!")
      .min(0, "Electricity Increase is not valid!")
      .required("Electricity Increase is required!"),
    electricityIncrease6Yr: number()
      .typeError("Electricity Increase must be a number!")
      .min(0, "Electricity Increase is not valid!")
      .required("Electricity Increase is required!"),
    averageEnergyCost: number()
    .typeError("Average Energy Cost must be a number!")
    .min(0, "Average Energy Cost is not valid!")
    .required("Average Energy Cost is required!"),
    monthlyConsumptionCost: array().of(number().typeError("Must be a number!").min(0, "Must be a positive number!"))
    // .test("min", "Should have at least 1 monthly value", function(value) {
    //   return sum(value) > 0;
    // })
  })
}

export const getValidationSchemaVictron = () => {
  return object().shape({
    client: string()
      .typeError("IOT client is required!")
      .required("IOT client is required!"),
    solarPanelAngle: number()
      .typeError("solarPanelAngle must be a number!")
      .min(0, "solarPanelAngle is not valid!")
      .required("solarPanelAngle is required!"),
    azimuth: number()
      .typeError("azimuth must be a number!")
      .min(0, "azimuth is not valid!")
      .required("azimuth is required!"),
    kwRate: number()
      .typeError("kwRate must be a number!")
      .min(0, "kwRate is not valid!")
      .required("kwRate is required!"),
    selectedDays: array()
      .min(2, "Select some days please!"),
    noBatteries: number()
      .typeError("Batteries must be a number!")
      .min(1, "Batteries is not valid!")
      .required("Batteries is required!"),
    systemLoss: number()
      .typeError("systemLoss must be a number!")
      .min(0, "systemLoss is not valid!")
      .max(100, "systemLoss is not valid!")
      .required("systemLoss is required!"),
    town: object({
      name: string().required("Town is required!"),
    })
    // .test(
    //   'is-not-uknown',
    //   'Cannot be unkown Town',
    //   value => value.name !== 'Unknown',
    // )
    //   .typeError("Town is required!")
    //   .required("Town is required!"),
  })
}

export const getValidationSchemaAnnouncement = () => {
  return object().shape({
    date: date().required("Date is required!"),
    title: string()
      .min(2, "Title is too short!")
      .required("Title is required!"),
    description: string()
      .min(2, "Decription is too short!")
      .required("Decription is required!"),
  })
}

export const getValidationSchemaProduct = () => {
  return object().shape({
    name: string().required("Name is required!"),
    barcode: string()
      .min(2, "Barcode is too short!")
      .required("Barcode is required!"),
    description: string()
      .min(2, "Description is too short!")
      .required("Description is required!"),
    category: string().required("Category is required!"),
    price: object().shape({
      retail: number()
        .typeError("Price must be a number!")
        .min(1, "Price is too short!")
        .required("Price is required!"),
      gold: number()
        .typeError("Price must be a number!")
        .min(1, "Price is too short!")
        .required("Price is required!"),
      reseller: number()
        .typeError("Price must be a number!")
        .min(1, "Price is too short!")
        .required("Price is required!"),
      coop: number()
        .typeError("Price must be a number!")
        .min(1, "Price is too short!")
        .required("Price is required!"),
    }),
  })
}

export const getValidationSchemaTicket = () => {
  return object().shape({
    subject: string().required("A subject is required!"),
    description: string().required("A description of the issue is required!"),
    email: string()
      .email("E-mail is not valid!")
      .required("E-mail is required!"),
    serialNumber: string(),
    product: string(),
  })
}


export const getValidationSchemaWarehouseProduct = () => {
  return object().shape({
    invoiceNo: string()
      .required("An invoice number is Required!"),
    notes: string(),
    serials: array().of(object().shape({
      serialNo: string().required(),
      product: string()
    })).min(1, "At least 1 Product is Required!"),
  })
}


export const getValidationSchemaClaim = () => {
  return object().shape({
    invoiceNo: string()
      .required("An invoice number is Required!"),
    purchaseDate: date().required("Purchase Date is required!"),
    failureDate: date().required("Failure Date is required!"),
    supplier: string().required("Supplier is required!"),
    productModel: string().required("Product Model is required!"),
    serialNo: string().required("Serial no is required!"),
    productModel: string().required("Product Model is required!"),
    approvedBy: string().required("Approved By is required!"),
    ticketNo: string().required("Ticket no is required!"),
    notes: string(),
  })
}


export const getValidationSchemaOrder = () => {
  return object().shape({
    email: string()
      .email("E-mail is not valid!")
      .required("E-mail is required!"),
    notes: string(),
    companyName: string().required("Company Name required!"),
    collect: string(),
    deliveryAddress: string().when("collect", {
      is: (val) => {
        return (val === 'deliver')
      },
      then: string().required('Delivery address is required!').min(5, 'Delivery address too short!'),
      otherwise: string(),
    }),
    poNumber: string()
  })
}

export const getValidationSchemaRooftop = () => {
  return object().shape({
    email: string().email("E-mail is not valid!"),
    customer: string()
      .min(2, "Customer is too short!")
      .required("Customer is required!"),
    phone: string().min(5, "Phone number is not valid!"),
    type: string().required("Type is required!"),
    roof: string().required("Roof is required!"),
    appliances: array()
      .of(
        object().shape({
          name: string().required("Name of appliance is required!"),
          amount: number()
            .typeError("Amount must be a number!")
            .min(1, "Amount is too short!")
            .required("Amount is required!"),
          hours: number()
            .typeError("Hours must be a number!")
            .min(1, "Hours is too short!")
            .required("Hours is required!"),
          watts: number().typeError("Wattage must be a number!"),
        })
      )
      .required("Appliances are required!")
      .min(1, "At least 1 appliance is needed!"),
  })
}

export const getValidationSchemaVictronOnboard = () => {
  return object().shape({
    roofLeng: number()
      .typeError("Roof Length required!")
      .min(0, "Roof Length required!")
      .required("Roof Length required!"),
    roofWidth: number()
      .typeError("Roof Width required!")
      .min(0, "Roof Width required!")
      .required("Roof Width required!"),
    stringDistance: number()
      .typeError("String distance required!")
      .min(0, "String distance required!")
      .required("String distance required!"),
    installStorey: number()
      .typeError("Storey required!")
      .min(1, "Storey required!")
      .required("Storey required!"),
    stringCableCost: number()
      .typeError("This is required!")
      .min(0, "This is required!")
      .required("This is required!"),
    acCableCost: number()
      .typeError("This is required!")
      .min(0, "This is required!")
      .required("This is required!"),
    fusesCost: number()
      .typeError("This is required!")
      .min(0, "This is required!")
      .required("This is required!"),
    storeyCost: number()
      .typeError("This is required!")
      .min(0, "This is required!")
      .required("This is required!"),
    installCost: number()
      .typeError("This is required!")
      .min(0, "This is required!")
      .required("This is required!"),
    installInverterCost: number()
      .typeError("This is required!")
      .min(0, "This is required!")
      .required("This is required!"),
    installBatteryCost: number()
      .typeError("This is required!")
      .min(0, "This is required!")
      .required("This is required!"),
    town: object()
      .test(
        'is-not-uknown',
        'Cannot be unkown Town',
        value => value.name !== 'Unknown',
      )
      .typeError("Town is required!")
      .required("Town is required!"),
    // roofOrientation: string()
    // .required("Roof Orientation required!"),
  })
}


export const getValidationSchemaProjectName = () => {
  return object().shape({
    name: string()
      .required("A Name is required!"),
  })
}

export const getValidationSchemaRep = () => {
  return object().shape({
    email: string()
      .email("E-mail is not valid!")
      .required("E-mail is required!"),
    name: string()
      .min(2, "Name is too short!")
      .required("E-mail is required!"),
    phone: string()
      .min(5, "Phone number is not valid!")
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone number is required!"),
    code: string()
      .matches(noSpaces, "No spaces for the code!")
      .required("E-mail is required!"),
  })
}

export const getValidationSchemaQuote = () => {
  return object().shape({
      quoteReference: string().required('Quote number or reference is required!'),
      notes: string()
  })
}

export const getValidationSchemaClientQuoteInputs = () => {
  return object().shape({
    email: string().email("E-mail is not valid!"),
    customer: string().required("Quote needs a customer name!").min(2, "Name is too short!"),
    phone: string()
      .min(5, "Phone number is not valid!")
      .matches(phoneRegExp, "Phone number is not valid"),
      address: string(),
  })
}

export const getValidationSchemaAlertMessage = () => {
  return object().shape({
    message: string(),
    alertType: string().required("Alert type is required!"),
  })
}